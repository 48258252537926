import { TokokoLogo } from '@bukuwarung/sachet'

const SplashScreenFallback = () => {
    return (
        <div
            className={`fixed left-0 top-0 z-50 flex h-full w-full max-w-full items-center justify-center overflow-auto bg-neutrals-light lg:relative lg:z-auto lg:h-auto lg:w-card xl:w-1/2`}>
            <TokokoLogo width={197} height={43}></TokokoLogo>
        </div>
    )
}

export default SplashScreenFallback
