import { IconLoaderCircle } from '@bukuwarung/sachet'

import IconLoader from './Icons/IconLoader'

interface LoaderProps {
    solidBackground?: boolean
    width?: number | string
    height?: number | string
    type?: 'default' | 'circle'
    position?: 'absolute' | 'relative' | 'static' | 'fixed'
}

const Loader = ({ solidBackground, width, height, type, position }: LoaderProps) => {
    return (
        <div
            data-testid="loader"
            className={`item-center left-0 top-0 z-10 flex h-full w-full justify-center bg-neutrals-light ${position || 'absolute'} ${
                solidBackground ? '' : 'bg-opacity-60'
            }`}>
            <span className="sr-only">loading</span>
            <div
                className="my-auto"
                style={{
                    width: width || 120,
                    height: height || 120
                }}>
                {type === 'circle' ? (
                    <IconLoaderCircle className="h-full w-full" primaryColor="#00645B"></IconLoaderCircle>
                ) : (
                    <IconLoader className="h-full w-full"></IconLoader>
                )}
            </div>
        </div>
    )
}

export default Loader
