interface LayoutBoxProps {
    children?: JSX.Element | JSX.Element[]
    prepend?: JSX.Element | JSX.Element[]
}

const LayoutBox = ({ children, prepend }: LayoutBoxProps) => {
    return (
        <div className="mx-auto flex max-w-full flex-col items-start" style={{ width: 520 }}>
            {prepend}
            <div className="flex w-full flex-col items-center rounded-xl bg-neutrals-light px-4 py-5 drop-shadow-sm sm:py-16">
                <div className="mx-auto w-96 max-w-full">{children}</div>
            </div>
        </div>
    )
}

export default LayoutBox
