import { Alert, Login, Spacer } from '@bukuwarung/sachet'
import { useRouter } from 'next/router'
import { Suspense, lazy, useState } from 'react'
import $axios from '~/lib/axios'

import { useGlobal } from '~/components/Global/GlobalProvider'
import { API_DEVICE_ID, AUTH_USER_ID_STORAGE_NAME, ENVIRONMENT, IS_PROD, USER_SERVICE_BASE_URL } from '~/constants'
import locale from '~/locale'
import { AccessTokenObject, LoginRequest, LoginResponse, OtpSendRequest, OtpSendResponse } from '~/types/auth'
import { sendAmplitudeEvent } from '~/utils/amplitude'
import { $error } from '~/utils/log'

import { OTPChannel } from '@bukuwarung/sachet/components/Login/OTPForm'
import { PhoneNumber } from '@bukuwarung/sachet/components/Login/PhoneNumberForm'
import jwtDecode from 'jwt-decode'
import Script from 'next/script'
import LayoutBox from '~/components/Layout/LayoutBox'
import Loader from '~/components/Loader'
import Meta from '~/components/Meta'
import SplashScreenFallback from '~/components/Onboarding/SplashScreenFallback'
import { requestInterceptor } from '~/lib/axiosInterceptor'
import { getErrorMessage } from '~/utils/api'
import { mapUserId } from '~/utils/auth'

const SplashScreen = lazy(() => import('../../components/Onboarding/SplashScreen'))

const LoginPage = () => {
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [OTPError, setOTPError] = useState('')
    const [token, setToken] = useState('')
    const { snackbar, appNonce } = useGlobal()
    const [channel, setChannel] = useState<OTPChannel>('WA')
    const [allowUser, setAllowUser] = useState<boolean>(true)
    const [turnstileToken, setTurnstileToken] = useState<string>()

    const [text, setText] = useState({
        title: 'Masuk',
        divider: 'atau gunakan'
    })

    const submitPhoneNumber = async ({ countryCode, phoneNumber }: PhoneNumber, callback: () => void, _channel: OTPChannel) => {
        if (loading || phoneNumberError.length) {
            return
        }

        setAllowUser(true)

        localStorage.removeItem('isReturningToBannedPopup_' + ENVIRONMENT)

        setLoading(true)
        const options = {
            headers: {
                'buku-origin': _channel === 'WA' ? 'tokoko' : 'tokoko-web'
            }
        }

        setChannel(_channel)

        $axios
            .post<OtpSendResponse>(
                '/next/api/otp/send',
                {
                    action: 'LOGIN_OTP_SELLER_WEB',
                    countryCode: countryCode,
                    deviceId: API_DEVICE_ID,
                    method: _channel,
                    phone: phoneNumber,
                    cToken: turnstileToken
                } as OtpSendRequest,
                options
            )
            .then(res => {
                setLoading(false)

                if (res.data.data.message === 'Not allowed') {
                    setAllowUser(false)
                    return
                }

                setToken(res.data?.data?.token || '')
                setText({ title: 'Verifikasi OTP', divider: '' })

                // send event
                sendAmplitudeEvent('user_login:otp_input_box_shown', {
                    method: _channel
                })

                callback()
            })
            .catch(err => {
                setLoading(false)

                setPhoneNumberError(getErrorMessage({}, err).error.message)
            })
    }

    const submitOTP = ({ countryCode, phoneNumber, OTP }: PhoneNumber & { OTP: string }) => {
        if (loading) {
            return
        }

        setLoading(true)
        setOTPError('')

        $axios
            .post<LoginResponse>(
                USER_SERVICE_BASE_URL + '/login/standalone',
                {
                    androidId: 'android-1',
                    countryCode: countryCode,
                    deviceBrand: 'Oneplus',
                    deviceId: API_DEVICE_ID,
                    deviceModel: 'ONEPLUS A6000',
                    otp: OTP,
                    phone: phoneNumber
                } as LoginRequest,
                {
                    headers: {
                        'x-ops-token': token
                    }
                }
            )
            .then(async res => {
                requestInterceptor(res.data.xsrfToken)

                const { auth_user_id }: AccessTokenObject = jwtDecode(res.data.idToken)
                localStorage.setItem(AUTH_USER_ID_STORAGE_NAME, auth_user_id)

                // send event
                sendAmplitudeEvent(
                    'user_login:otp_verify_button_clicked',
                    {
                        status: res.status === 200 ? 'success' : 'fail',
                        method: channel
                    },
                    () => {
                        mapUserId(
                            res.data.idToken,
                            router,
                            () => {
                                // success
                                router.push('/')
                            },
                            () => {
                                snackbar.snackbarHandle({
                                    value: true,
                                    style: 'error',
                                    message: locale.id.common.request_error
                                })
                            }
                        )
                    }
                )
            })
            .catch(err => {
                $error(err)
                setLoading(false)

                // send event
                sendAmplitudeEvent('user_login:otp_verify_button_clicked', {
                    status: 'fail',
                    method: channel
                })

                setOTPError(getErrorMessage({}, err).error.message)
            })
    }

    const validatePhoneNumber: (value: string) => {
        valid: boolean
        errorMessage: string
    } = value => {
        if (!IS_PROD || value.length < 5) {
            return {
                errorMessage: '',
                valid: true
            }
        }

        if (value.length < 9 || value.length > 13) {
            return {
                errorMessage: 'Pastikan nomor HP terdiri dari 9-13 digit',
                valid: false
            }
        }

        return {
            errorMessage: '',
            valid: true
        }
    }

    const initTurnstile = () => {
        const element = document.getElementById(appNonce)
        if (element) {
            window.turnstile.render(element, {
                sitekey: '0x4AAAAAAAkHH76tct2L1bqN',
                callback: function (token: string) {
                    setTurnstileToken(token)
                }
            })
        }
    }

    return (
        <>
            <Meta title={`${text.title} | Tokoko`} type="auth"></Meta>

            <Script
                src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"
                nonce={appNonce}
                onLoad={initTurnstile}></Script>

            <div className="flex h-full w-full flex-grow overflow-hidden">
                <Suspense fallback={<SplashScreenFallback></SplashScreenFallback>}>
                    <SplashScreen></SplashScreen>
                </Suspense>
                <div className="flex flex-grow overflow-auto">
                    <div className="mx-auto my-auto max-w-full p-4">
                        <LayoutBox>
                            <div className="flex items-center">
                                <div className="relative w-full" data-testid="login-container">
                                    {!allowUser && (
                                        <>
                                            <Alert
                                                message={
                                                    <span>
                                                        {locale.id.login.not_allow_error_text}{' '}
                                                        <a href="https://belajar.tokoko.id/pengumuman/" target="_blank" rel="noreferrer">
                                                            klik disini
                                                        </a>
                                                    </span>
                                                }
                                                open
                                                type="error"
                                            />
                                            <Spacer height={20} />
                                        </>
                                    )}

                                    <Login
                                        channel={channel}
                                        phoneFormTitle="Masukkan Nomor WhatsApp"
                                        defaultCountryCode="+62"
                                        phoneNumberError={phoneNumberError}
                                        phoneNumberFormLoading={loading || !turnstileToken}
                                        validateNumber={validatePhoneNumber}
                                        onPhoneNumberError={value => setPhoneNumberError(value)}
                                        onSubmitPhoneNumber={(value, callback) => submitPhoneNumber(value, callback, channel)}
                                        OTPFormLoading={loading}
                                        onResendOTP={value => submitPhoneNumber(value, () => null, channel)}
                                        onSendWithAlternative={(value, _channel) => submitPhoneNumber(value, () => null, _channel)}
                                        OTPError={OTPError}
                                        onSubmitOTP={value => submitOTP(value)}
                                        onOTPError={value => setOTPError(value)}></Login>

                                    <Spacer height={20} />
                                    <div className="flex w-full justify-center">
                                        <div id={appNonce}></div>
                                    </div>
                                    {loading && <Loader></Loader>}
                                </div>
                            </div>
                        </LayoutBox>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage
